/* src/styles.css */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(36 50 93);
  font-size: 40px;
  text-align: center;
}

a {
  text-decoration: none;
  color: #3a57e8;
  transition: color 0.3s;
}

a:hover {
  color: #1a3d7c;
}
p {
  /* font-weight: 700;
  font-size: 14px;
  line-height: 20px; */
  color: #414146;
  text-align: center;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 25px !important;
  margin: 8px 0 5px 0;
}
span {
  color: #414146;
  /* text-align: center; */
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 10px !important;
  margin: 8px 0 5px 0;
}
li {
  color: #414146;
  /* text-align: center; */
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  margin: 8px 0 5px 0;
}
